import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import CTA from '../components/CTA'
import VCA from '../images/vca.png'
import Bigwin from '../images/bigwin.png'
import Medwin from '../images/medwin.png'
import Smallwin from '../images/smallwin.png'
import Bighonor from '../images/bighonor.png'
import Medhonor from '../images/medhonor.png'
import Smallhonor from '../images/smallwin.png'
import Bigfinal from '../images/finalbig.png'
import Medfinal from '../images/finalmed.png'
import Smallfinal from '../images/finalsmall.png'



export default () => (
    <div id="app">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Voters' Choice Awards "></meta>
          <title> Voter Choice Awards - 2019 | Raleigh | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/congratulations-to-all-2019-wral-com-voters-choice-awards-winners/" />
        </Helmet>
      <PreHeader/>
      <Header/>
      <h1 className="vca--title">Congratulations to all 2019 WRAL.com Voters’ Choice Awards Winners!</h1>
      <div className="vca">
      <img src = {VCA} className="vca--image" alt = "camera woman with headphones" />

        <div className="vca_grid">
        <div className="vca-one">
        <div className="vca_wrapper">
          <h2 className="vca--subhead">Finalists, honorable mentions and winners have all been posted on the ballot page</h2>
          <p className="vca_text">
          After over 20,000 nominations, more than 150,000 votes, countless posts on social media and three months of hoping, the <a target="_blank" rel="noopener noreferrer" className="primaryHue" href="https://wral5.secondstreetapp.com/2019-WRAL-Voters-Choice-Awards/" >results of the WRAL.com Voters’ Choice Awards</a>, presented by Millennium Buildings, have been announced! Congratulations to all who were even nominated – the awards were more competitive than ever before, and this year featured winners from all across the state.
          </p>
          <h2 className="vca--subhead">Finalists come from all over NC</h2>
          <p className="vca_text">
            Over 147 categories ran the gamut of the best businesses, places, people and things our fine state has to offer. North Carolina residents came out in droves to support their faves, their hidden gems and their ol’ reliables. We were excited to see the variety of new finalists, more than we’ve had since we began the awards in 2015.

            In our fifth year of the awards, we aimed to expand our geographic footprint even further across North Carolina, and we were glad to welcome new finalists from as far away as Wrightsville and Asheville in the east and west, Clayton, Greensboro, Fuquay-Varina and more in the Piedmont. Our finalists are multiple award-winning and widely-recognized in their geographic areas, including restaurants by Ashley Christensen, some of the best accounting firms and heating/air conditioning contractors, the most popular festivals and vacation destinations across the state, and more.
          </p>
          <h2 className="vca--subhead">For the finalists – share your accomplishments</h2>
          <p className="vca_text">
            Below are graphics that can be used on your websites and social media pages, and we want you to share your honors with your fans! Whether you were a finalist, honorable mention or winner, you can thank the people who helped you make it through the voting phase of the awards. You can use these graphics throughout this year, along with a link to the <a target="_blank" rel="noopener noreferrer" className="primaryHue" href="https://wral5.secondstreetapp.com/2019-WRAL-Voters-Choice-Awards/" >WRAL.com Go-To Guide</a>, a compilation of the Voters’ Choice Awards results that will serve to help new residents, tourists and others find the best of the best, no matter what they’re searching for. Your business will come up when people search for WRAL’s recommendations in 2020, and will be published in stories throughout the year around WRAL.com. And if you would like to be notified of the awards when they launch later this year, sign up for updates below. Emails will only concern the WRAL.com Voters’ Choice Awards schedule, so you won’t receive anything until closer to August!
          </p>
          <h2 className="vca--subhead">Sharing Graphics</h2>
          <div className="vca_graphic">
            <img className="vca_graphic--asset big" src = {Bigwin} alt = "something" />
            <p className="vca_graphic--text">1080x1350</p>
          </div>
          <div className="vca_graphic">
            <img className="vca_graphic--asset med" src = {Medwin} alt = "something" />
            <p className="vca_graphic--text">1200×630</p>
          </div>
          <div className="vca_graphic">
            <img className="vca_graphic--asset small" src = {Smallwin} alt = "something" />
            <p className="vca_graphic--text">1500×500</p>
          </div>
          <div className="vca_graphic">
            <img className="vca_graphic--asset big" src = {Bighonor} alt = "something" />
            <p className="vca_graphic--text">1080x1350</p>
          </div>
          <div className="vca_graphic">
            <img className="vca_graphic--asset med" src = {Medhonor} alt = "something" />
            <p className="vca_graphic--text">1200×630</p>
          </div>
          <div className="vca_graphic">
            <img className="vca_graphic--asset small" src = {Smallhonor} alt = "something" />
            <p className="vca_graphic--text">1500×500</p>
          </div>
          <div className="vca_graphic">
            <img className="vca_graphic--asset big" src = {Bigfinal} alt = "something" />
            <p className="vca_graphic--text">1080x1350</p>
          </div>
          <div className="vca_graphic">
            <img className="vca_graphic--asset med" src = {Medfinal} alt = "something" />
            <p className="vca_graphic--text">1200×630</p>
          </div>
          <div className="vca_graphic">
            <img className="vca_graphic--asset small" src = {Smallfinal} alt = "something" />
            <p className="vca_graphic--text">1500×500</p>
          </div>

        </div>







      <h3 className="vca--subhead">Our TV and Radio properties include:</h3>
           </div>
      <div className="vca-two">
      {/* <img src = {tvRadio} className="vca--image" alt = "camera woman with headphones" /> */}
      </div>
      </div>
      <div className="vca_banner">
          <h3 className="vca_banner-text">
            To get started today <a href="tel:+1-919-744-3030">call</a> or fill out our contact form.
          </h3>
        <div className="vca_cta">
            <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--mobile"/>
            <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--desktop"/>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
  );
